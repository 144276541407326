<template>
  <PageContainer center-vertically="true">
    <PageTitle register-screen="true">
      {{ trans('FORM_TITLE:LogIn') }}
    </PageTitle>
    <t-card variant="auth">

      <ValidationObserver ref="form" v-slot="{ invalid }">
        <form
            ref="loginForm"
            @submit.prevent="handleFormSubmit"
        >
          <RegisterCard>
            <template #picture>
              <img class="w-full" :src="themeConfig.staticPhotos.loginMain" />
            </template>
            <template #content>
              <div class="flex flex-col gap-y-3 my-auto">
                <FieldWrapper>
                  <TextField
                    field="email"
                    rules="email|required"
                    name="email"
                    autocomplete
                    :placeholder="trans('LABEL:Email')"
                    v-model="email"
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <TextField
                    isPassword
                    field="password"
                    rules="required"
                    :placeholder="trans('LABEL:Password')"
                    v-model="password"
                  />
                </FieldWrapper>
                <div class="flex flex-row justify-end">
                  <CheckboxField
                    v-model="rememberMe"
                    :label="trans('BUTTON:RememberMe')"
                  />
                </div>
              </div>
              <div class="flex items-center justify-start mt-3 gap-1 text-sm">
                {{ trans('LABEL:ForgotPassword') }}&nbsp;
                <Button
                  variant="link"
                  class="mr-3"
                  :to="{name: 'auth-forgot-password'}"
                  :text="trans('BUTTON:ForgotYourPasswordLink')"
                />
              </div>
            </template>
            <template #bottom-left>
              {{ trans('TEXT:LoginNoAccountYet') }}&nbsp;
              <Button
                :to="{name: 'auth-register'}"
                variant="underline"
                :text="trans('BUTTON:SignIn')"
              />
            </template>
            <template #bottom-right>
              <Button
                type="submit"
                :disabled="invalid"
                :loading="status.loggingIn"
                :text="trans('BUTTON:LogMeIn')"
              />
            </template>
          </RegisterCard>
        </form>
      </ValidationObserver>
    </t-card>
  </PageContainer>
</template>

<script>
import themeConfig from '@/themeConfig.js'
import { mapState, mapActions, mapGetters } from 'vuex'
import Button from "@/utils/crud/components/Button";
import FieldWrapper from "@/utils/crud/components/field-types/components/FieldWrapper";
import TextField from "@/utils/crud/components/field-types/Text";
import CheckboxField from "@/utils/crud/components/field-types/Checkbox";
import PageContainer from "@/layouts/components/pages/PageContainer.vue";
import PageTitle from "@/layouts/components/pages/PageTitle.vue";
import RegisterCard from "@/views/content/register/RegisterCard.vue";

export default {
  data () {
    return {
      email: '',
      password: '',
      rememberMe: false,
    }
  },
  components: {
	  RegisterCard,
    PageTitle,
    PageContainer,
    CheckboxField,
    Button, TextField, FieldWrapper
  },
  computed: {
	  themeConfig() {
		  return themeConfig
	  },
    ...mapState('account', ['status']),
    ...mapGetters('account', ['errors'])
  },
  methods: {
    ...mapActions('account', ['login', 'logout']),
    ...mapActions('chat', ['getNotReadCount']),
    handleFormSubmit (e) {
      e.preventDefault();

      const { email, password, rememberMe } = this;
      if (email && password) {
        if(this.$refs.form.validate()) {

          this.login({ email, password, rememberMe })
        }
      }
    }
  },
  setup() {

    return {
      icons: {
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
    }
  },
}
</script>

<style lang="scss" >
</style>
